import React, { useState, useRef } from 'react';
import './App.css';
import { Auth } from './components/Auth';
import { Chat } from './components/Chat';

// meta tags
import { Helmet } from "react-helmet";

import { auth } from './firebase-config';
import { signOut } from 'firebase/auth';


import Cookies from 'universal-cookie';
const cookies = new Cookies();


// meta tags
class Application extends React.Component {
  render() {
    return (
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>My Title</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
      </div>
    );
  }
};


function App() {
  const [isAuth, setIsAuth] = useState(cookies.get("auth-token"));
  const [room, setRoom] = useState(null);
  const roomInputRef = useRef(null);

  const signUserOut = async () => {
    await signOut(auth);
    cookies.remove('auth-token');
    setIsAuth(false);
    setRoom(null);
  };

  if ( !isAuth ) {
    return (
      <div className="App">
        <div className='container'>
          <Auth setIsAuth={setIsAuth} />
        </div>
      </div>
    );
  } 

  return (
    <div className='container'> 
      {room ? (
        <Chat room={room} />
      ) : (
        <div className="room">
          <label>Gib den Chatnamen ein:</label> 
          <input ref={roomInputRef} />
          <button className='button' onClick={() => setRoom(roomInputRef.current.value)}>
            Chatname
          </button>

          <br></br>
          {/* vorhandene Chats anzeigen */}


        </div>
      ) } 

      <button className='sign-out button' onClick={signUserOut}>Ausloggen</button>
      
      <footer className='footer'>
        <p>Bei Nutzung dieser Websites akzeptierst du die Datenschutz und Nutzungsbedinungen von Google Services.</p>
        <a href='https://policies.google.com/privacy'>Datenschutz Google</a>
        <span> | </span>
        <a href="https://cloud.google.com/terms/">Google Cloud</a>
        <span> | </span>
        <a href='https://firebase.google.com/terms'>Firebase</a>
      </footer>
    </div>
  );


}

export default App;
