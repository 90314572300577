import { auth, provider } from '../firebase-config.js';
import { signInWithPopup } from 'firebase/auth';

import Cookies from 'universal-cookie';
const cookies = new Cookies();



export const Auth = (props) => {

    const { setIsAuth } = props;

    const singInWithGoogle = async () => {
        try {

            const result = await signInWithPopup(auth, provider);
            console.log(result);
            cookies.set("auth-token", result.user.refreshToken);
            setIsAuth(true);
        } catch (err) {
            console.log(err);
        }

    }
    return (<div className="auth">
        <h3>Melde dich an</h3>
        <button className="button login" onClick={singInWithGoogle}>Login :)</button>
        <br></br>
        <br></br>
        <p>Ich übernehme keine Haftung</p>

        <footer className='footer'>
            <p>Bei Nutzung dieser Websites akzeptierst du die Datenschutz und Nutzungsbedingungen von Google Services.</p>
            <a href='https://policies.google.com/privacy'>Datenschutz Google</a>
            <span> | </span>
            <a href="https://cloud.google.com/terms/">Google Cloud</a>
            <span> | </span>
            <a href='https://firebase.google.com/terms'>Firebase</a>
        </footer>
    </div>);
};