import { useEffect, useState } from 'react';
import { addDoc, collection, onSnapshot, serverTimestamp, query, where, orderBy } from 'firebase/firestore';
import { auth, db } from '../firebase-config';
import "../styles/Chat.css";

import GifPicker from 'gif-picker-react';


export const Chat = (props) => {
    const { room } = props;
    const [newMessage, setNewMessage] = useState('');
    const [messages, setMessages] = useState([]);
    
    const messagesRef = collection(db, 'messages');

    useEffect(() => {
        const queryMessages = query(messagesRef, where('room', '==', room), orderBy('createdAt', 'desc') );
        const cleanup = onSnapshot(queryMessages, (snapshot) => {
            // console.log('neue Nachricht: ' + snapshot);
            let messages = [];
            snapshot.forEach((doc) => {
                messages.push({...doc.data(), id: doc.id});
            });
            setMessages(messages);
        });
        // for performance
        return () => cleanup();
    }, []);

    const handleSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }

        if (newMessage === '') return;

        await addDoc(messagesRef, {
            text: newMessage,
            createdAt: serverTimestamp(),
            user: auth.currentUser.displayName,
            room: room,
        });


        setNewMessage('');
    };

    // -----------------
    // Gif auswählen

    const handleGifClick = (tenorImage) => {
        const gifUrl = tenorImage.url;

        // Now, you can do whatever you want with the GIF URL, such as sending it to the database
        // For simplicity, let's set it in the state
        setNewMessage(gifUrl);

        // Programmatically submit the form
        // handleSubmit(new Event("submit"));

    };

    return (
        <div className=''>
            <div className="chat-app">
                <div className='header'>
                    <h1>Willkommen im: {room}-Chat</h1>
                </div>

                <div className='messages'>
                    {messages.map((message) => (
                        <div className='message' key={message.id}>
                            <span className='user'>{message.user}</span>
                            {message.text.includes('media.tenor.com') ? (
                                // is a gif
                                <img src={message.text} alt="Tenor GIF" />
                            ) : (
                                // normal message
                                <div>{message.text}</div>
                            )}
                        </div>
                    ))}
                </div>

                <form onSubmit={handleSubmit} className="new-message-form">
                    <input 
                    className="new-message-input" 
                    placeholder="Gib deine Nachricht ein..."
                    onChange={(e) => setNewMessage(e.target.value)}
                    value={newMessage}
                    />
                    <br></br>
                    <button type="submit" className="send-button button">Senden</button>                    
                </form>

                {/* Gif */}
                <div className='gif-picker-container'>
                    <p>Gif auswählen und dann auf Senden klicken.</p>
                    <GifPicker onGifClick={handleGifClick} tenorApiKey={"AIzaSyBZ-cX8e9W1wJM61TScKNqb_lQd8rlRYj4"} />
                </div>

            </div>
        </div>
    );
}