// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCAfJogH_7IBL8MvdgeCPb-W0eEvgDAM70",
  authDomain: "react-tutorial-5cc8b.firebaseapp.com",
  projectId: "react-tutorial-5cc8b",
  storageBucket: "react-tutorial-5cc8b.appspot.com",
  messagingSenderId: "831010768710",
  appId: "1:831010768710:web:24ff82844f9b5c5d48c747"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);